import { AxiosResponse } from 'axios';
import { defineStore }   from 'pinia';
import { ref }           from 'vue';

import CartApi                                from '@/api/cart-api';
import PaymentsApi                            from '@/api/payments-api';
import { CheckoutInfo, CheckoutThankYouData } from '@/types/cart';
import { PaymentInfoResponse }                from '@/types/payments';
import { captureError }                       from '@/utils/error-logger';

const useCheckoutStore = defineStore('checkout', () => {
    const checkout = ref<CheckoutInfo | null>(null);
    const paymentInfo = ref<PaymentInfoResponse | null>(null);

    const selectedShippingMethodId = ref<number |null>(null);

    const checkoutThankYouData = ref<CheckoutThankYouData | null>(null);

    function reset(): void {
        checkout.value = null;
        paymentInfo.value = null;
        selectedShippingMethodId.value = null;
        checkoutThankYouData.value = null;
    }

    async function fetchCheckoutInfo(): Promise<AxiosResponse<CheckoutInfo>> {
        return CartApi.getCheckoutInfo()
            .then(response => {
                checkout.value = response.data;

                selectedShippingMethodId.value = checkout.value?.shippingType.id;

                const checkoutComment = window.sessionStorage.getItem('cart-comment');
                if (checkout.value.comment && !checkoutComment) {
                    window.sessionStorage.setItem('cart-comment', checkout.value.comment);
                }

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function fetchPaymentInfo() {
        return CartApi.getCartPaymentInfo()
            .then(async response => {
                paymentInfo.value = response.data;

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function updateShippingInfo(shippingMethodId: number) {
        return CartApi.updateShippingInfo(shippingMethodId)
            .then(async response => {
                await fetchCheckoutInfo();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function updatePaymentMethod(paymentMethodId: number) {
        return CartApi.updatePaymentMethod(paymentMethodId)
            .then(async response => {
                await Promise.all([
                    fetchPaymentInfo(),
                    fetchCheckoutInfo(),
                ]);

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function updateKlarnaSession() {
        return PaymentsApi.updateKlarnaSession()
            .then(async response => {
                await fetchCheckoutInfo();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function addTrustedShopInsurance(cartId: number) {
        return CartApi.addTrustedShopInsurance(cartId)
            .then(async response => {
                await fetchCheckoutInfo();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function removeTrustedShopInsurance(cartId: number) {
        return CartApi.removeTrustedShopInsurance(cartId)
            .then(async response => {
                await fetchCheckoutInfo();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function updateCartCheckoutComment(comment: string) {
        return CartApi.updateCartCheckoutComment(comment)
            .then(async response => {
                await fetchCheckoutInfo();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function fetchThankYouData() {
        return CartApi.getThankYouData()
            .then(response => {
                checkoutThankYouData.value = response.data;
            });
    }

    return {
        checkout,
        paymentInfo,
        selectedShippingMethodId,
        checkoutThankYouData,
        reset,
        fetchCheckoutInfo,
        fetchPaymentInfo,
        updateShippingInfo,
        updatePaymentMethod,
        updateKlarnaSession,
        addTrustedShopInsurance,
        removeTrustedShopInsurance,
        updateCartCheckoutComment,
        fetchThankYouData,
    };
});

export default useCheckoutStore;
