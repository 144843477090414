import axios    from '@/api/api';
import {
    KlarnaPaymentMethod,
    PayOneUpdateDetailsPayload,
    TargobankInfoResponse,
    UpdateKlarnaSessionResponse,
}               from '@/types/payments';
import getRoute from '@/utils/routing';

class PaymentsApi {
    async createPayPalOrder() {
        return axios.post<{ id?: string; }>(
            getRoute('paypal_express_order'),
        );
    }

    async finalizePayPalExpressPayment(orderId: string) {
        return axios.post<{ cartReviewRequired?: boolean; }>(
            getRoute('paypal_express_finalize'),
            { order_id: orderId },
        );
    }

    async getKlarnaInfoForPaymentMethod(paymentMethodId: number) {
        return axios.get<KlarnaPaymentMethod>(
            getRoute(
                'api_v1_carts_get_klarna_payment_method',
                { paymentMethodId: paymentMethodId },
            ),
        );
    }

    async updateKlarnaMethod(klarnaPaymentMethod: KlarnaPaymentMethodCategoryName) {
        return axios.post(
            getRoute('api_v1_klarna_update'),
            { 'payment_method_category': klarnaPaymentMethod },
        );
    }

    async updateKlarnaToken(token: string) {
        return axios.post(
            getRoute('api_v1_klarna_update'),
            { token },
        );
    }

    async updateKlarnaSession() {
        return axios.post<UpdateKlarnaSessionResponse>(
            getRoute('api_v1_klarna_session_update'),
        );
    }

    async updatePayOneInfo(payload: PayOneUpdateDetailsPayload) {
        return axios.post(
            getRoute('payment_payone_update'),
            payload,
        );
    }

    async getTargobankInfo() {
        return axios.get<TargobankInfoResponse>(
            getRoute('api_v1_targo_bank_get_info'),
        );
    }

    async updateTargobankInfo(duration: number) {
        return axios.post(
            getRoute('api_v1_targo_bank_update'),
            { duration },
        );
    }
}

export default (new PaymentsApi());
