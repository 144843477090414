import { AxiosError }    from 'axios';
import { defineStore }   from 'pinia';
import { computed, ref } from 'vue';

import CustomerApi        from '@/api/customer-api';
import PageApi            from '@/api/page-api';
import {
    AccountRegistrationTypes,
    BasicRegistrationPayload,
    Customer as CustomerType,
    FullRegistrationPayload,
    UpdateLoginInfoPayload,
    UpdatePersonalDataPayload,
}                         from '@/types/account';
import { PageHeaderData } from '@/types/shop';
import { captureError }   from '@/utils/error-logger';

const useCustomerStore = defineStore('customer', () => {
    const customer = ref<CustomerType | null>(null);

    const isAuthenticated = computed(() => customer.value !== null);

    const pageHeaderData = ref<PageHeaderData>({
        isLoggedIn:    null,
        isReseller:    null,
        isGuestBuyer:  null,
        cartItemCount: null,
    });

    const passwordRecoveryError = ref<string>();

    function reset(): void {
        customer.value = null;
        pageHeaderData.value = {
            isLoggedIn:    null,
            isReseller:    null,
            isGuestBuyer:  null,
            cartItemCount: null,
        };
    }

    /**
     * Fetches the customer from backend, set store value and return customer value
     */
    async function fetchCustomer(): Promise<CustomerType> {
        return CustomerApi.getCustomer()
            .then(response => {
                customer.value = response.data;

                return customer.value;
            })
            .catch((error: AxiosError) => {
                if (error.response && error.response.status === 401) {
                    customer.value = null;
                } else {
                    captureError(error);
                }

                return Promise.reject(error);
            });
    }

    async function getCustomer(): Promise<CustomerType> {
        if (customer.value === null) {
            return fetchCustomer();
        }

        return Promise.resolve(customer.value);
    }

    /**
     * Log the customer in, set the store value and return the response
     */
    async function login(email: string, password: string, type: AccountRegistrationTypes, isInCheckout: boolean) {
        const response = await CustomerApi.login(email, password, type, isInCheckout);

        if (response.status === 200 && customer.value !== null) {
            reset();
        }

        customer.value = response.data;

        return response;
    }

    async function googleAuth(credentials: string) {
        const response = await CustomerApi.googleAuth(credentials);

        await fetchCustomer();

        return response;
    }

    /**
     * Log out the customer, set store value to null and return the response
     */
    async function logout() {
        return await CustomerApi.logout();
    }

    /**
     * Register the customer, set store value and return the response
     */
    async function register(payload: BasicRegistrationPayload | FullRegistrationPayload) {
        const response = await CustomerApi.register(payload);

        if (response.status === 200 && customer.value !== null) {
            reset();
        }

        customer.value = response.data;

        return response;
    }

    /**
     * Set new password for the customer, fetch new customer and return the response
     */
    async function setPassword(password: string, confirmPassword: string, recoveryCode: string) {
        return CustomerApi.changePassword(password, confirmPassword, recoveryCode)
            .then(async response => {
                if (isAuthenticated.value) {
                    await fetchCustomer();
                }

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Request reset password email, fetch customer and return the response
     */
    async function resetPassword(email: string) {
        return CustomerApi.resetPassword(email)
            .then(async response => {
                if (isAuthenticated.value) {
                    await fetchCustomer();
                }

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function checkPasswordRecoveryCode(passwordRecoveryCode: string) {
        return CustomerApi.checkPasswordRecoveryCode(passwordRecoveryCode)
            .catch(error => {
                captureError(error as Error);

                return Promise.reject(error);
            });
    }

    async function checkPaypalSetPasswordCode(passwordRecoveryCode: string) {
        return CustomerApi.checkPayPalSetPasswordCode(passwordRecoveryCode)
            .catch(error => {
                captureError(error as Error);

                return Promise.reject(error);
            });
    }

    function setPasswordRecoveryError(error: string): void {
        passwordRecoveryError.value = error;
    }

    function clearPasswordRecoveryError(): void {
        passwordRecoveryError.value = undefined;
    }

    async function magicLink(email: string) {
        return CustomerApi.magicLink(email)
            .then(async response => {

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Update the customer personal data, fetch customer and return the response
     */
    async function updateCustomerPersonalData(payload: UpdatePersonalDataPayload) {
        return CustomerApi.updatePersonalData(payload)
            .then(async response => {
                await fetchCustomer();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Attempt to delete customer account, set store value to null and return the response
     */
    async function removeCustomer(password: string, cb?: (() => void)) {
        return await CustomerApi.removeAccount(password)
            .then(response => {
                customer.value = null;

                if (typeof cb !== 'undefined') {
                    cb();
                }

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    /**
     * Update the customer login info, fetch customer and return the response
     */
    async function updateCustomerLoginInfo(payload: UpdateLoginInfoPayload) {
        return CustomerApi.updateLoginInfo(payload)
            .then(async response => {
                await fetchCustomer();

                return response;
            })
            .catch(error => {
                captureError(error);

                return Promise.reject(error);
            });
    }

    async function getPageHeaderData() {
        return PageApi.getHeaderData()
            .then(async response => {
                pageHeaderData.value = response.data;

                return response;
            })
            .catch(error => {
                captureError(error);

                pageHeaderData.value = {
                    isLoggedIn:    null,
                    isReseller:    null,
                    isGuestBuyer:  null,
                    cartItemCount: null,
                };

                return Promise.reject(error);
            });
    }

    /**
     * Expose store values and methods
     */
    return {
        customer,
        isAuthenticated,
        pageHeaderData,
        passwordRecoveryError,
        reset,
        fetchCustomer,
        getCustomer,
        login,
        googleAuth,
        logout,
        register,
        setPassword,
        resetPassword,
        checkPasswordRecoveryCode,
        checkPaypalSetPasswordCode,
        setPasswordRecoveryError,
        clearPasswordRecoveryError,
        magicLink,
        updateCustomerPersonalData,
        removeCustomer,
        updateCustomerLoginInfo,
        getPageHeaderData,
    };
});

export default useCustomerStore;

export const accountDeletedSessionKey     = 'account-deleted';
