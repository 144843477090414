import { AxiosResponse } from 'axios';

import axios              from '@/api/api';
import { PageHeaderData } from '@/types/shop';
import getRoute           from '@/utils/routing';

type WithoutNullableKeys<T> = {
    [key in keyof T]: NonNullable<T[key]>;
}

class PageApi {
    private headerDataRequest: Promise<AxiosResponse<WithoutNullableKeys<PageHeaderData>>> | null = null;

    async getHeaderData() {
        if (this.headerDataRequest === null) {
            this.headerDataRequest = axios.get<WithoutNullableKeys<PageHeaderData>>(
                getRoute('api_v1_page_get_header_data'),
            );

            this.headerDataRequest?.finally(() => {
                this.headerDataRequest = null;
            });
        }

        return this.headerDataRequest;
    }
}

export default (new PageApi());
